import React, { useEffect } from 'react';
import { Header as OnboardingHeader, Next } from "components/Onboarding/OnboardingComponents"
import IBuiltThis from "components/Post/IBuiltThis";
import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 64px;
`;

export default function projectFinished() {
  
  return (
    <>
      <OnboardingHeader />
      <IBuiltThis title={(typeof window != "undefined") ? localStorage.getItem("projectTitle") : null} projectSlug={(typeof window != "undefined") ? localStorage.getItem("projectSlug") : null} numericalId={(typeof window != "undefined") ? localStorage.getItem("numericalId") : null} onboarding={true}/>
      <ButtonContainer>
        <Next
          paintDrip
          to="/home"
          hex="#438cee"
          duration={1}
        >
          Sweet, what's next &#8250;
        </Next>
      </ButtonContainer>
    </>
  )
}
